import React from "react";
import Bootstrap, { Carousel, BImg } from "bootstrap-4-react";
import one from "../assets/img/1.png";
import two from "../assets/img/2.png";
import three from "../assets/img/3.png";
import four from "../assets/img/4.png";
import five from "../assets/img/5.png";

const CarouselComponent = () => {
  return (
    <Carousel w="100" id="carouselExampleIndicators">
      <Carousel.Indicators>
        <Carousel.Indicator target="#carouselExampleIndicators" to="0" active />
        <Carousel.Indicator target="#carouselExampleIndicators" to="1" />
        <Carousel.Indicator target="#carouselExampleIndicators" to="2" />
        <Carousel.Indicator target="#carouselExampleIndicators" to="3" />
        <Carousel.Indicator target="#carouselExampleIndicators" to="4" />
      </Carousel.Indicators>
      <Carousel.Inner>
        <Carousel.Item active>
          <BImg display="block" w="100" src={one} />
        </Carousel.Item>
        <Carousel.Item>
          <BImg display="block" w="100" src={two} />
        </Carousel.Item>
        <Carousel.Item>
          <BImg display="block" w="100" src={three} />
        </Carousel.Item>
        <Carousel.Item>
          <BImg display="block" w="100" src={four} />
        </Carousel.Item>
        <Carousel.Item>
          <BImg display="block" w="100" src={five} />
        </Carousel.Item>
      </Carousel.Inner>
      <Carousel.Prev href="#carouselExampleIndicators">
        <Carousel.Prev.Icon />
      </Carousel.Prev>
      <Carousel.Next href="#carouselExampleIndicators">
        <Carousel.Next.Icon />
      </Carousel.Next>
    </Carousel>
  );
};

export default CarouselComponent;
