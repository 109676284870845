import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../assets/img/favlogo.png";
import menu from "../assets/img/menu.png";
import i1 from "../assets/img/image-1.png";
import i2 from "../assets/img/image-2.png";
import gplay from "../assets/img/gplay.png";
import apps from "../assets/img/apps.png";

const NavbarComponent = () => {
  const history = useHistory();
  const location = useLocation();

  const [isOpen, setOpen] = useState(false);

  const handleClick = (path) => {
    setOpen(false);
    history.push(path);
  };

  return (
    <>
      <div className="container-bar">
        <div className="new-navbar">
          <img
            className="logo"
            src={logo}
            alt=""
            onClick={() => history.push("/")}
          />
          <ul>
            <li
              onClick={() => history.push("/")}
              className={location.pathname === "/" ? "active" : ""}
            >
              HOME
            </li>
            <li
              onClick={() => history.push("/about-us")}
              className={location.pathname === "/about-us" ? "active" : ""}
            >
              ABOUT US
            </li>
            <li
              onClick={() => history.push("/contact")}
              className={location.pathname === "/contact" ? "active" : ""}
            >
              CONTACT
            </li>
            <li style={{ paddingRight: "0px" }}>
              <img src={i1} alt="" className="pr-2" />
              <img src={i2} alt="" />
            </li>
          </ul>
          <div className="menu-btn">
            <img
              className={isOpen ? "on-active" : ""}
              src={menu}
              alt=""
              onClick={() => setOpen(!isOpen)}
            />
          </div>
        </div>
        <div className={isOpen ? "menu-mobile" : "hide"}>
          <p
            onClick={() => handleClick("/")}
            className={location.pathname === "/" ? "active" : ""}
          >
            HOME
          </p>
          <p
            onClick={() => handleClick("/about-us")}
            className={location.pathname === "/about-us" ? "active" : ""}
          >
            ABOUT US
          </p>
          <p
            onClick={() => handleClick("/contact")}
            className={location.pathname === "/contact" ? "active" : ""}
          >
            CONTACT
          </p>
          <div className="mobile-menu">
            <img src={gplay} alt="" className="mobile-menu-img" />
            <img src={apps} alt="" className="mobile-menu-img" />
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarComponent;
