import * as React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

const SimpleAccordion = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const data = [
    {
      summary: "Berapa harga minyak goreng bekas per liter yang ditawarkan?",
      detail:
        "Harga bisa jadi berbeda-beda setiap hari, berdasarkan pergerakan harga dunia. Namun perubahan tidak terlalu signifikan. Faktor lokasi kota pun dapat mempengaruhi harga yang kami tawarkan. List harga dapat dilihat pada halaman Points/Liter di JERIGEN. Sebagai gambaran, harga minyak jelantah yang kami tawarkan untuk di daerah Jabodetabek perliter adalah 5 poin, atau Rp 5.000 ketika ditukar dengan uang.",
    },
    {
      summary: "Bagaimana cara menjual minyak goreng bekas ke Jerigen?",
      detail:
        "Terdapat dua cara untuk menjual minyak jelantah yang telah dikumpulkan di dalam jerigen. Yang pertama dengan datang langsung ke depo terdekat, anda dapat menemukannya via fitur Search di JERIGEN. Setelah sampai di depo, petugas kami akan mengarahkan anda untuk men-scan barcode melalui aplikasi, kemudian minyak jelantah yang anda bawa akan ditimbang oleh petugas dan hasilnya dimasukkan ke dalam aplikasi. Setelah itu poin akan bertambah secara otomatis ke akun JERIGEN anda. Yang kedua anda dapat menggunakan fitur Pick Up di JERIGEN.  Anda akan diminta untuk menentukan lokasi pengambilan minyak jelantah dan memasukkan jumlah jerigen yang akan dijual. Transporter kami akan datang ke lokasi penjemputan paling lambat 2 jam setelah permintaan penjemputan. Transporter akan datang dan memindahkan minyak jelantah ke dalam jerigennya, atau menukarkannya dengan jerigen yang dibawa oleh Transporter. Kemudian Transporter akan membawa jerigen berisi minyak jelantah ke depo terdekat untuk kemudian ditimbang oleh petugas. Hasil dari timbangan akan dimasukkan ke dalam sistem kami dan poin akan masuk ke akun anda dan akun Transporter. Transporter akan mendapatkan 30 poin dari setiap penjemputan yang dilakukan.",
    },
    {
      summary: "Bagaimana cara menjadi Transporter Jerigen?",
      detail:
        "Anda dapat melakukan pendaftaran melalui aplikasi JERIGEN – Transporter yang dapat didownload melalui Google Play Store dan Apple Store. Namun perlu diingat, JERIGEN membatasi jumlah Transporter agar dapat menyeimbangi jumlah Agent of Change di JERIGEN. Hal ini dilakukan demi mensejahterakan setiap Transporter yang terdaftar di JERIGEN sehingga demand dan supply seimbang dan saling menguntungkan.",
    },
    {
      summary: "Bagaimana cara membuka agen depo Jerigen?",
      detail:
        "Agen depo Jerigen diperuntukkan bagi masyarakat yang masih melakukan transaksi secara konvensional tanpa melalui aplikasi. Siapapun yang memiliki ruangan di rumah maupun tempat usahanya, dapat mendaftar menjadi agen depo Jerigen. Cukup menghubungi kami via telefon, WhatsApp, maupun email, kami akan segera datang dan mensurvey lokasi anda. Agen depo Jerigen berhak menerima komisi dari setiap liter minyak goreng bekas yang terjual melalui agen depo tersebut.",
    },
    {
      summary:
        "Apakah pengepul minyak goreng bekas bisa menjual ke Jerigen atau menjadi mitra Jerigen?",
      detail:
        "Agen depo Jerigen diperuntukkan bagi masyarakat yang masih melakukan transaksi secara konvensional tanpa melalui aplikasi. Siapapun yang memiliki ruangan di rumah maupun tempat usahanya, dapat mendaftar menjadi agen depo Jerigen. Cukup menghubungi kami via telefon, WhatsApp, maupun email, kami akan segera datang dan mensurvey lokasi anda. Agen depo Jerigen berhak menerima komisi dari setiap liter minyak goreng bekas yang terjual melalui agen depo tersebut.",
    },
  ];

  return (
    <div className="contact-accord">
      {data.map((row, i) => {
        return (
          <Accordion
            key={i}
            expanded={expanded === i}
            onChange={handleChange(i)}
          >
            <AccordionSummary aria-controls={i} id={i}>
              <Typography className="accord-sum">{row.summary}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className=" mobile-accord">{row.detail}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default SimpleAccordion;
