import * as React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

const SimpleAccordion = ({ type }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const bg = [
    {
      summary: "Ketidakpedulian",
      detail:
        "Kesadaran dan pengetahuan masyarakat yang kurang terhadap pengelolaan sampah dan proses daur ulangnya",
    },
    {
      summary: "Melelahkan",
      detail:
        "Pemerintah maupun pihak-pihak terkait kewalahan dengan sampah minyak goreng bekas yg tercecer dan dibuang ke saluran air sehingga menyebabkan penyumbatan",
    },
    {
      summary: "Tercampur aduk",
      detail:
        "Terkadang sampah minyak goreng bekas dibuang ke tong sampah, sehingga tercampur aduk, bau, dan akhirnya menjadi menumpuk dan mencemari lingkungan",
    },
  ];

  const utopia = [
    {
      summary: "Pemilahan Sampah",
      detail:
        "Mendorong masyarakat untuk memisahkan sampah minyak goreng bekas menggunakan jerigen atau medium lainnya",
    },
    {
      summary: "Daur Ulang",
      detail:
        "Mendaur ulang sampah minyak goreng bekas menjadi produk maupun energi terbarukan",
    },
    {
      summary: "Menguntungkan",
      detail:
        "Memberikan kompensasi dalam bentuk yang bisa dirupiahkan kepada mereka yang mengumpulkan sampah minyak goreng bekas",
    },
  ];

  const data = type === "utopia" ? utopia : bg;

  return (
    <div className={type === "utopia" ? "utopia-accord" : "bg-accord"}>
      {data.map((row, i) => {
        return (
          <Accordion
            key={i}
            expanded={expanded === i}
            onChange={handleChange(i)}
          >
            <AccordionSummary aria-controls={i} id={i}>
              <Typography className="accord-sum">{row.summary}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{row.detail}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default SimpleAccordion;
