import React from "react";
import { Card, Col, Row } from "bootstrap-4-react";
import Avatar from "@material-ui/core/Avatar";

const CardComponent = ({ img, content, type, user, title, isColorize }) => {
  return (
    <React.Fragment>
      <Card mr="3" style={{ width: "18rem" }} className="card-contact bold">
        <div>{img}</div>
        <Card.Body>
          {(type === "tim" || type === "contact") && (
            <Row>
              <Col col="md-12">
                <h4 style={{ fontFamily: "inter-bold" }}>{user.name}</h4>
              </Col>
              <Col style={{ fontFamily: "inter-bold" }}>
                {user.type === "address" ? (
                  <h6 style={{ fontStyle: "italic" }}>{user.position}</h6>
                ) : (
                  <h5 style={{ fontStyle: "italic" }}>{user.position}</h5>
                )}
              </Col>
            </Row>
          )}
          {title && <h2 style={{ textAlign: "center" }}>{title}</h2>}
          {content && <Card.Text>{content}</Card.Text>}
          {type === "avatar" && (
            <>
              <Avatar
                alt="Cindy Baker"
                src="/static/images/avatar/3.jpg"
                className="my-3"
              />
              <h4>{user.name}</h4>
              <p>{user.location}</p>
            </>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default CardComponent;
