import React, { useEffect } from "react";
import Accordion from "../components/Accordion";
import Card from "../components/Card";
import { Row, Col } from "bootstrap-4-react";
import Slider from "../components/Slider";

import tim1 from "../assets/img/tim1.png";
import tim2 from "../assets/img/tim2.png";
import tim3 from "../assets/img/tim3.png";
import adv1 from "../assets/img/adv1.png";
import adv2 from "../assets/img/adv2.png";
import adv3 from "../assets/img/adv3.png";
import icon1 from "../assets/img/business.png";
import icon2 from "../assets/img/vespa.png";
import icon3 from "../assets/img/water.png";
import about from "../assets/img/about.png";
import bg from "../assets/img/bg.png";
import utopia from "../assets/img/utopia.png";
import Aos from "aos";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 2000 });
  }, []);

  const visi = [
    {
      title: "Visi",
      content:
        "Menjadi agen perubahan dunia dengan amanah mulia dalam melestarikan lingkungan yang terus berkelanjutan dengan menerapkan gerakan ekonomi sirkular sepenuh hati",
    },
    {
      title: "Misi",
      content:
        "Menciptakan ekosistem yang mengintegrasikan pengelolaan sampah, teknologi digital, dan nilai ekonomi untuk mengubah perilaku manusia menuju lingkungan yang lebih baik dan hijau",
    },
  ];

  const advisor = [
    {
      image: adv1,
      user: {
        name: "Safitri Siswono",
        position: "Owner Bangun Cipta Grup",
      },
    },
    {
      image: adv2,
      user: {
        name: "Boyke Rachmanda",
        position: "Owner Lintas Negara ",
      },
    },
    {
      image: adv3,
      user: {
        name: "Verania Andria",
        position: "Senior Advisor for Renewable Energy UNDP",
        type: "address",
      },
    },
  ];

  const tim = [
    {
      image: tim1,
      user: {
        name: "Ilham Perintis",
        position: "CBDO",
      },
      content:
        "Memiliki segudang pengalaman di organisasi internasional di dalam maupun mancanegara, Ilham masih memiliki ambisi lebih untuk berkontribusi kepada bangsa dan masyarakat. Pria lulusan sarjana dan master dari Universitas Indonesia ini memiliki tujuan memperbaiki pola pikir dan perilaku bangsa Indonesia agar tak tertinggal jauh dari bangsa lainnya. Jerigen adalah wujud nyata dari misi yang dia emban untuk bumi yang lebih baik.",
    },
    {
      image: tim2,
      user: {
        name: "M. Fachry Muchtar",
        position: "CEO",
      },
      content:
        "Selepas lulus dari Fatih Universitesi di Turki dan Hochschule Worms di Jerman, Fachry bertekad untuk pulang ke tanah air dan membangun usahanya sendiri. Fachry juga sempat menyelesaikan pendidikan masternya di Institut Teknologi Bandung jurusan MBA di tengah kesibukan menjalankan usahanya. Bermimpi untuk melahirkan karya yang berdampak luas sekaligus membantu melestarikan Bumi, Jerigen terlahir untuk mewujudkan mimpinya tersebut.",
    },
    {
      image: tim3,
      user: {
        name: "Laelnaldi Saputra",
        position: "CTO",
      },
      content:
        "Jenius teknologi dari Universitas Mercubuana ini sudah malang melintang di dunia IT. Sempat memiliki karir yang cemerlang di salah satu perusahaan Singapura, Aldi berkeinginan untuk menciptakan sesuatu yang berskala luas. Jerigen tak hanya membantu melestarikan bumi, namun juga dapat melahirkan jenis-jenis pekerjaan baru yang dapat menyerap banyak tenaga kerja. Aldi yakin dapat mengambil peran dalam misi Jerigen yang luhur itu.",
    },
  ];

  const icon = [
    {
      image: icon1,
      label: "Agent of Change",
      value: 100,
    },
    {
      image: icon2,
      label: "Transporter",
      value: 3,
    },
    {
      image: icon3,
      label: "Liter",
      value: 192,
    },
  ];
  return (
    <div>
      <div className="about-section-1 mb-5" data-aos="slide-up">
        <img src={about} alt="" />
      </div>
      <Row alignItems="center" className="home-section-2" data-aos="slide-up">
        {icon.map((row, i) => {
          return (
            <Col col="md-4" className="mt-3" key={i}>
              <img src={row.image} />
              <h1>{row.value}</h1>
              <h5>{row.label}</h5>
            </Col>
          );
        })}
        {/* {icon.map((row, i) => {
          return (
            <Col col="md-4" key={i}>
              <h1>{row.value}</h1>
              <h5>{row.label}</h5>
            </Col>
          );
        })} */}
      </Row>
      <Row alignItems="center" className="home-section-3" data-aos="slide-up">
        <Col col="md-12">
          <h2 className="mb-4">Latar Belakang</h2>
          <p>
            Sampah telah menjadi masalah tak terkendali yang sangat sulit untuk
            dipecahkan, tak terkecuali sampah minyak goreng bekas. Kami hadir
            dengan misi menciptakan ekosistem berbasis masyarakat untuk
            pengelolaan sampah minyak goreng bekas yang melibatkan mereka secara
            proaktif serta membuka mata pencaharian baru bagi masyarakat
            lainnya.
          </p>
        </Col>
        <Col col="md-6" className="bg">
          <img src={bg} alt="" />
        </Col>
        <Col col="md-6" className="mt-3">
          <Accordion />
        </Col>
      </Row>
      <div className="section-container">
        <Row alignItems="center" className="home-section-4" data-aos="slide-up">
          <Col col="md-12">
            <h2 className="mb-4">Utopia</h2>
            <p>
              Kita tidak bisa menyelesaikan masalah sampah ini secara
              keseluruhan, akan tetapi kita semua bisa aktif membantu untuk
              menguranginya. Kami mengadaptasi filosofi ekonomi sirkular dengan
              membuat sistem tempat pembuang, penjemput, dan pengepul sampah
              minyak goreng bekas terintegrasi di dalam satu platform, Jerigen.
            </p>
          </Col>
          <Col col="md-6">
            <Accordion type="utopia" />
          </Col>
          <Col col="md-6" className="bg mt-3">
            <img src={utopia} alt="" />
          </Col>
        </Row>
      </div>
      <Row alignItems="center" className="home-section-3" data-aos="slide-up">
        <Col col="md-12">
          <h2 className="mb-4">Bagaimana kami memulainya?</h2>
          <p>
            Berawal dari obrolan warung kopi tiga pemuda tentang kehidupan,
            masalah sosial, kebersihan, dan bumi, tercetuslah ide untuk
            mengambil peran dalam usaha pelestarian bumi. Dimulai dari hal
            terkecil yang semua orang bisa lakukan, mengumpulkan sampah minyak
            goreng yang telah terpakai di dapur. Banyak sekali masalah yang bisa
            dicegah apabila sampah minyak goreng bekas dibiarkan tercecer atau
            terbuang di selokan, seperti banjir, pencemaran tanah dan air,
            hingga berbagai virus dan penyakit.{" "}
          </p>
          <p className="pb-3">
            Pergerakan kami sederhana, mengetok rumah dari pintu ke pintu untuk
            memberikan jerigen kosong agar masyarakat mudah untuk membuang
            sampah minyak goreng. Pintu ke pintu, rumah ke rumah, RT ke RT,
            kecamatan ke kecamatan, hingga terus sampai setiap dapur yang ada di
            Indonesia, memiliki sebotol jerigen tempat pembuangan minyak goreng
            bekas di sudut ruangannya. Semua berawal dari: Jerigen.
          </p>
        </Col>
        {visi.map((row, i) => {
          return (
            <Col col="md-6" key={i} className="mt-4" className="ta-left vm">
              <Card content={row.content} title={row.title}></Card>
            </Col>
          );
        })}
        <Col col="md-12">
          <h2 className="my-4">Tim Kami</h2>
        </Col>
        {tim.map((row, i) => {
          return (
            <Col col="md-4" key={i} className="mt-4" className="ta-center crew">
              <Card
                isColorize={true}
                type="tim"
                content={row.content}
                user={row.user}
                img={row.image}
              ></Card>
            </Col>
          );
        })}
        <Col col="md-12">
          <h2 className="my-4">Advisor Kami</h2>
        </Col>
        {advisor.map((row, i) => {
          return (
            <Col col="md-4" key={i} className="mt-4" className="ta-center adv">
              <Card type="tim" user={row.user} img={row.image}></Card>
            </Col>
          );
        })}
      </Row>
      <div className="section-container mt-4">
        <Row alignItems="center" className="home-section-4" data-aos="slide-up">
          <Col col="md-12">
            <h2 className="mb-4">Gallery</h2>
          </Col>
          <Col col="md-12">
            <Slider></Slider>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
