import React from "react";
import { Card } from "bootstrap-4-react";
import Avatar from "@material-ui/core/Avatar";

const CardComponent = ({ img, content, type, user, title, isColorize }) => {
  return (
    <React.Fragment>
      <Card
        display="inline-block"
        align="top"
        mr="3"
        style={{ width: "18rem" }}
        className={isColorize ? "bg-colorize" : ""}
      >
        {type !== "avatar" && img && <Card.Image src={img} top />}
        <Card.Body>
          {(type === "tim" || type === "contact") && (
            <>
              <h4>{user.name}</h4>
              {user.type === "address" ? (
                <h6 style={{ fontStyle: "italic" }}>{user.position}</h6>
              ) : (
                <h5 style={{ fontStyle: "italic" }}>{user.position}</h5>
              )}
            </>
          )}
          {title && <h2 style={{ textAlign: "center" }}>{title}</h2>}
          {content && <Card.Text>{content}</Card.Text>}
          {type === "avatar" && (
            <div className="ava">
              <Avatar alt="Cindy Baker" src={img} className="my-3" />
              <h4>{user.name}</h4>
              <p>{user.location}</p>
            </div>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default CardComponent;
