import React from "react";
import { Row, Col } from "bootstrap-4-react";
import facebook from "../assets/img/facebook.png";
import utube from "../assets/img/utube.png";
import linkedin from "../assets/img/linkedin.png";
import instagram from "../assets/img/instagram.png";
import { useHistory } from "react-router-dom";
import logo from "../assets/img/favlogo-white.png";

const Footer = () => {
  const history = useHistory();

  const handlePush = (link) => {
    window.open(link, "_blank").focus();
  };

  return (
    <Row className="footer">
      <Col col="md-3" className="left ">
        <h3 className="my-3">Jerigen</h3>
        <p onClick={() => history.push("/")} className="link">
          Home
        </p>
        <p onClick={() => history.push("/about-us")} className="link">
          About Us
        </p>
        <p onClick={() => history.push("/contact")} className="link">
          Contact
        </p>
      </Col>
      <Col col="md-3" className="left">
        <h3 className="my-3">Legal</h3>
        <p>Terms and Policies</p>
        <p onClick={() => history.push("/privacy-policy")}>Privacy Policy</p>
      </Col>
      <Col col="md-6" className="right">
        <img src={logo} alt="" className="mb-3 logo" />
        <div className="socmed">
          {/* <a href="https://www.instagram.com/jerigen.official"> */}
          <img
            src={instagram}
            alt=""
            onClick={() =>
              handlePush("https://www.instagram.com/jerigen.official")
            }
          />
          {/* </a> */}
          {/* <a href="https://youtube.com/channel/UCVW6fFudjaT4qsdaQuXd64A"> */}
          <img
            src={utube}
            alt=""
            onClick={() =>
              handlePush("https://youtube.com/channel/UCVW6fFudjaT4qsdaQuXd64A")
            }
          />
          {/* </a> */}
          {/* <a href="https://www.facebook.com/jerigen.official"> */}
          <img
            src={facebook}
            alt=""
            onClick={() =>
              handlePush("https://www.facebook.com/jerigen.official")
            }
          />
          {/* </a> */}
          {/* <a href="https://www.linkedin.com/company/jerigen"> */}
          <img
            src={linkedin}
            alt=""
            onClick={() =>
              handlePush("https://www.linkedin.com/company/jerigen")
            }
          />
          {/* </a> */}
        </div>
        <p>© Jerigen 2021</p>
      </Col>
    </Row>
  );
};

export default Footer;
