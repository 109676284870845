import React, { useEffect } from "react";

import Carousel from "../components/Carousel";
import Card from "../components/Card";
import { Row, Col } from "bootstrap-4-react";
import card1 from "../assets/img/card-1.png";
import card2 from "../assets/img/card-2.png";
import card3 from "../assets/img/card-3.png";
import card4 from "../assets/img/card-4.png";
import icon1 from "../assets/img/business.png";
import icon2 from "../assets/img/vespa.png";
import icon3 from "../assets/img/water.png";
import mockup from "../assets/img/mockup-phone.png";
import gplay from "../assets/img/gplay.png";
import apps from "../assets/img/apps.png";
import lorem from "../assets/img/lorem.png";
import home from "../assets/img/home.png";
import Aos from "aos";
import ava1 from "../assets/img/ava1.png";
import ava2 from "../assets/img/ava2.png";
import ava3 from "../assets/img/ava3.png";
import igc from "../assets/img/ig-color.png";

import { Typography } from "@material-ui/core";

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);

  const card = [
    {
      image: card1,
      content: "Kumpulkan minyak goreng bekas pemakaian anda di dalam jerigen",
    },
    {
      image: card2,
      content:
        "Jual minyak goreng bekas anda kepada kami, dengan datang langsung ke depo kami",
    },
    {
      image: card3,
      content:
        "Atau transporter kami akan datang menjemput dengan menggunakan fitur Pick Up di aplikasi Jerigen",
    },
    {
      image: card4,
      content:
        "Dapatkan poin dari setiap minyak goreng bekas yang anda jual, tukarkan dengan uang atau sumbangkan bagi mereka yang membutuhkan",
    },
  ];
  const logo = [1, 2, 3, 4];

  const review = [
    {
      image: ava1,
      user: {
        name: "Erdina Kusumawati",
        location: "Agent of Change, Jakarta",
      },
      content:
        "Jerigen mengedukasi kami agar rutin mengganti minyak goreng dengan minyak yang baru demi menjaga kesehatan keluarga. Dengan dihargainya minyak bekas terpakai, kami dapat membeli minyak goreng baru tanpa khawatir terlalu mahal.",
    },
    {
      image: ava2,
      user: {
        name: "Puja Raditya",
        location: "Transporter, Bekasi",
      },
      content:
        "Saya bersyukur bisa bergabung menjadi Transporter Jerigen. Selain bisa mendapatkan penghasilan untuk mencari nafkah, saya juga dapat menyebarkan kebaikan dalam menjaga bumi dari limbah sampah minyak goreng.",
    },
    {
      image: ava3,
      user: {
        name: "John Smith",
        location: "BluePeace",
      },
      content:
        "Jerigen bergerak sesuai dengan misi organisasi kami yang bergerak di bidang lingkungan. Dengan keberadaan Jerigen, semakin banyak orang yang sadar akan kebersihan lingkungan, maka akan semakin baik dampak positif bagi kelestarian bumi.",
    },
  ];

  const icon = [
    {
      image: icon1,
      label: "Agent of Change",
      value: 100,
    },
    {
      image: icon2,
      label: "Transporter",
      value: 3,
    },
    {
      image: icon3,
      label: "Liter",
      value: 192,
    },
  ];

  return (
    <div>
      <Row alignItems="center" className="home-section-1" data-aos="slide-up">
        <Col col="md-6">
          <Carousel />
        </Col>
        <Col
          col="md-6"
          className="home"
          style={{
            backgroundImage: `url(${home})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
          }}
        >
          <Row style={{ textAlign: "right" }}>
            <Col col="md-12">
              <h1 className="home-title">Welcome, </h1>
              <h1 className="home-title">Agent of Change!</h1>
            </Col>
            <Col col="md-12" className="mt-5">
              <p className="home-subtitle">
                Jadilah agen perubahan dunia, dimulai dari{" "}
                <b> dapur rumahmu!</b>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row alignItems="center" className="home-section-2" data-aos="slide-up">
        {icon.map((row, i) => {
          return (
            <Col col="md-4" className="mt-3" key={i}>
              <img src={row.image} />
              <h1>{row.value}</h1>
              <h5>{row.label}</h5>
            </Col>
          );
        })}
        {/* {icon.map((row, i) => {
          return (
            <Col col="md-4" key={i}>
              <h1>{row.value}</h1>
              <h5>{row.label}</h5>
            </Col>
          );
        })} */}
      </Row>
      <Row alignItems="center" className="home-section-3" data-aos="slide-up">
        <Col col="md-12">
          <h2>Siap menjadi agen perubahan?</h2>
          <p>
            Jerigen menerima dan mengumpulkan minyak goreng bekas dari Agent of
            Change yang langsung datang ke depo kami, atau melalui Transporter
            di aplikasi Jerigen. Minyak goreng bekas kami olah menjadi produk
            dan energi terbarukan yang selaras dengan misi pemerintah untuk
            mengembangkan sistem ekonomi sirkular. Agent of Change dan
            Transporter berhak menerima poin dari setiap minyak goreng bekas
            yang kami terima, untuk kemudian ditukarkan dengan uang Rupiah
            maupun didonasikan.
          </p>
        </Col>
        {card.map((row, i) => {
          return (
            <Col col="md-6" key={i} className="mt-4">
              <Card img={row.image} content={row.content}></Card>
            </Col>
          );
        })}
      </Row>
      <div className="section-container" data-aos="slide-up">
        <Row alignItems="center" className="home-section-4">
          <Col col="md-3" className="info">
            <h2>Download Jerigen SEKARANG!</h2>
            <p>
              Bumi telah melakukan banyak hal untuk kita, saatnya kita melakukan
              yang terbaik untuk bumi tercinta!
            </p>
            <p>
              Dapatkan botol jerigen GRATIS dengan mendownload aplikasi Jerigen
              dan memberikan kode referral ke teman anda!
            </p>
          </Col>
          <Col col="md-6">
            <img className="mockup" src={mockup} alt="" />
          </Col>
          <Col col="md-3" className="download">
            <img src={gplay} alt="" />
            <img src={apps} alt="" className="mt-3" />
          </Col>
        </Row>
      </div>
      <Row alignItems="center" className="home-section-3" data-aos="slide-up">
        <Col col="md-12">
          <h2 className="mb-4">Apa Kata Mereka</h2>
        </Col>
        {review.map((row, i) => {
          return (
            <Col
              col="md-4"
              key={i}
              className="mt-4"
              className="ta-left section-5 review"
            >
              <Card
                type="avatar"
                content={row.content}
                img={row.image}
                user={row.user}
              ></Card>
            </Col>
          );
        })}
        <Col col="md-12" className="mt-4">
          <div
            className="follow"
            onClick={() =>
              window
                .open("https://instagram.com/jerigen.official/", "_blank")
                .focus()
            }
          >
            <img src={igc} alt="" />
            <p>Ikuti kisah dan cerita kami di Instagram</p>
          </div>
        </Col>
      </Row>
      <Row alignItems="center" className="home-section-4" data-aos="slide-up">
        <Col col="md-12">
          <h2>Partner dan Acknowledgement</h2>
        </Col>
        {logo.map((row) => {
          return (
            <Col col="md-3" key={row} className="mt-4">
              <img src={lorem} alt="" />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Home;
