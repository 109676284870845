import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import gal1 from "../assets/img/gal-1.png";
import gal2 from "../assets/img/gal-2.png";
import gal3 from "../assets/img/gal-3.png";
import gal4 from "../assets/img/gal-4.png";
import gal5 from "../assets/img/gal-5.png";
import gal6 from "../assets/img/gal-6.png";
import gal7 from "../assets/img/gal-7.png";
import gal8 from "../assets/img/gal-8.png";
import gal9 from "../assets/img/gal-9.png";

import gg1 from "../assets/img/gg1.png";
import gg2 from "../assets/img/gg2.png";
import gg3 from "../assets/img/gg3.png";
import gg4 from "../assets/img/gg4.png";

export default function SimpleSlider() {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  //   const data = [gal1, gal2, gal3, gal4, gal5, gal6, gal7, gal8, gal9];
  const data = [gg1, gg2, gg3, gg4];
  return (
    <Slider {...settings}>
      {data.map((row, i) => {
        return (
          <div key={i} className="slider">
            <img src={row} alt="" />
          </div>
        );
      })}
    </Slider>
  );
}
