import React, { useEffect } from "react";
import FAQ from "../components/FAQ";
import CardContact from "../components/CardContact";
import { Row, Col } from "bootstrap-4-react";
import tim1 from "../assets/img/instagram.png";
import tim2 from "../assets/img/email.png";
import tim3 from "../assets/img/pin.png";
import adv1 from "../assets/img/adv1.png";
import adv2 from "../assets/img/adv2.png";
import adv3 from "../assets/img/adv3.png";
import contact from "../assets/img/contact.png";
import Aos from "aos";
import RoomIcon from "@mui/icons-material/Room";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailIcon from "@mui/icons-material/Mail";
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 2000 });
  }, []);

  const contacts = [
    {
      image: (
        <InstagramIcon
          sx={{ color: "white", fontSize: "100px" }}
          className="icon"
        />
      ),
      user: {
        name: "Follow Us",
        position: "@jerigen.official",
      },
    },
    {
      image: <MailIcon sx={{ color: "white", fontSize: "100px" }} />,
      user: {
        name: "Write Us",
        position: "halo@jerigen.id",
      },
    },
    {
      image: <RoomIcon sx={{ color: "white", fontSize: "100px" }} />,
      user: {
        name: "Headquarter",
        position: `Gedung Jaya Lantai 9 Blok A02 Jl. MH Thamrin No. 12, Jakarta 10310`,
        type: "address",
      },
    },
  ];

  return (
    <div className="contact">
      <div className="about-section-1 mb-5" data-aos="slide-up">
        <img src={contact} alt="" />
      </div>
      <Row alignItems="center" className="home-section-3" data-aos="slide-up">
        {contacts.map((row, i) => {
          return (
            <Col col="md-4" key={i} className="mt-4" className="ta-center ">
              <CardContact
                type="contact"
                isColorize={true}
                user={row.user}
                img={row.image}
              ></CardContact>
            </Col>
          );
        })}
      </Row>
      <Row alignItems="center" className="home-section-3" data-aos="slide-up">
        <Col col="md-12" className="my-5">
          <h2 className="mb-4">Frequently Asked Questions</h2>
          <FAQ />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
